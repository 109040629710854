/*
 * @Description: 
 * @Version: 2.0
 * @Date: 2020-06-15 14:12:37
 * @LastEditors: *
 * @LastEditTime: 2020-06-30 16:41:35
 */ 
import "../asset/lib/swiper.css";
import "@/scss/public.scss";

// 截取字符串前几位
function cutString(str, num) {
  if (
    str !== null &&
    str !== undefined &&
    (typeof str == "string" || typeof str == "number")
  ) {
    return str.substring(0, num);
  } else {
    return str;
  }
}

document.documentElement.style.fontSize =
  (document.documentElement.clientWidth / 414) * 100 + "px";
document.addEventListener(
  "DOMContentLoaded",
  function (e) {
    document.documentElement.style.fontSize =
      (document.documentElement.clientWidth / 414) * 100 + "px";
  },
  false
);

//浏览器窗口大小变化时重新计算高度
$(window).resize(function () {
  document.documentElement.style.fontSize =
    (document.documentElement.clientWidth / 414) * 100 + "px";
  // console.log("尺寸变化");
});

$(document).ready(function() {
  $('.header').tap(function () {
    event.stopPropagation()
  });
  $(document).tap(function(){
    $('.header ul').removeClass('open')
  })
});

// url参数获取
export function getURLParameter(url, paramName) {
  var urlParams = url.split("?")[1]; //或者url.search获取参数字符串
  var paramArray = urlParams.split("&");
  var len = paramArray.length;
  var paramObj = {};//json对象
  var arr = [];//数组对象
  var key
  for (var i = 0; i < len; i++) {
    arr = paramArray[i].split("=");
    paramObj[arr[0]] = arr[1];
  }
  for (key in paramObj) {
    if (key == paramName) {
      return paramObj[paramName];
      break;
    }
  }
}
